var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card__item",
      class: {
        "is-alert": _vm.cardsTotal > _vm.limit[0],
        "is-danger": _vm.cardsTotal > _vm.limit[1]
      }
    },
    [
      _c("h3", { staticClass: "card__item-heading" }, [
        _vm._v(_vm._s(_vm.card.heading))
      ]),
      _c("p", { staticClass: "card__item-price" }, [
        _vm._v(_vm._s(_vm._f("euroCurrency")(_vm.card.price)))
      ]),
      _c(
        "button",
        {
          staticClass: "button button--03",
          attrs: { disabled: _vm.cardsTotal > _vm.limit[2] },
          on: { click: _vm.buyCard }
        },
        [_vm._v(" Buy card ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }