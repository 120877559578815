var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "cards" }, [
    _c("h1", { staticClass: "heading" }, [
      _vm._v("Buy cards, calculation demo")
    ]),
    _vm._m(0),
    _c("ul", { staticClass: "cards__list" }, [
      _c("li", [
        _vm._v("When going "),
        _c("strong", [
          _vm._v(
            "over " + _vm._s(_vm.limit[0]) + ", the cards will turn orange"
          )
        ]),
        _vm._v(".")
      ]),
      _c("li", [
        _vm._v("Going "),
        _c("strong", [
          _vm._v("over " + _vm._s(_vm.limit[1]) + " will turn them red")
        ]),
        _vm._v(".")
      ]),
      _c("li", [
        _vm._v("And going "),
        _c("strong", [
          _vm._v("over " + _vm._s(_vm.limit[2]) + " will disable")
        ]),
        _vm._v(" the 'Buy card' -button.")
      ])
    ]),
    _c("h2", { staticClass: "sub-heading" }, [
      _vm._v(
        "Vuex stored total: " + _vm._s(_vm._f("euroCurrency")(_vm.cardsTotal))
      )
    ]),
    _c("p", { staticClass: "cards__reset" }, [
      _c(
        "button",
        {
          staticClass: "button button--02",
          attrs: { disabled: _vm.cardsTotal == 0 },
          on: { click: _vm.confirmResetTotal }
        },
        [_vm._v(" Reset total ")]
      )
    ]),
    _c(
      "div",
      { staticClass: "cards__grid" },
      _vm._l(_vm.cards, function(card, index) {
        return _c("CardItem", {
          key: index,
          attrs: { card: card, cardsTotal: _vm.cardsTotal, limit: _vm.limit },
          on: { onIncrementCardsTotal: _vm.incrementCardsTotal }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "paragraph" }, [
      _vm._v(
        " Using parent and child components. Repeating child components based on data array in parent. And having the child "
      ),
      _c("code", [_vm._v("$emit()")]),
      _vm._v(" an event to parent. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }